<template>
  <v-container>
    <v-row class="text-justify">
      <v-col cols="12" v-html="dynamicContent&&dynamicContent.imprints">
      </v-col>
      <v-col cols="12 mb-12">
        <h2 class="nomargin_top">{{$t('delivery.lang_imprint')}}</h2>
        <div v-html="$t('delivery.lang_imprintContent')"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

export default {
  name: "ImpressumComponent",
  computed:{
    ...createNamespacedHelpers("Config").mapGetters(["dynamicContent",]),
  }
};
</script>
