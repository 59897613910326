<template>
  <div>
    <!--start impressum-component-->
    <impressum-component />
    <!--end impressum-component-->
  </div>
</template>

<script>
//import components
import ImpressumComponent from "@/components/ImpressumComponent.vue";

export default {
  name: "impressum",

  components: { ImpressumComponent },
};
</script>
